import React, { useEffect, useState } from 'react';
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { CSmartPagination } from '@coreui/react-pro';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { fetchExpertIssueResolved, fetchExpertList } from '../../store/reducers/userData/apiThunk';
import flag from '../../assets/svg/flag.svg'
import './style.css'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';

const UserListingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState([]);
  // eslint-disable-next-line
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [idSelected, setIdSelected] = useState('')

  const handleClickOpen = (id) => {
    setIdSelected(id)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userListing = async () => {
    const requestData = {
      "page": activePage,
    }
    const data = await dispatch(fetchExpertList(requestData));
    const { payload: { status, data: { expertList, page } } } = data;
    if (status && expertList && expertList.length > 0) {
      setUsersData(expertList);
      setTotalPages(page);
    }
  }

  useEffect(() => {
    userListing();
    // eslint-disable-next-line
  }, [activePage]);

  const ticketFun = async () => {
    const requestData = {
      "id": idSelected,
    }
    const data = await dispatch(fetchExpertIssueResolved(requestData));
    const { payload: { status, message } } = data;
    if (status ) {
      handleClose()
      userListing();
      ToastNotifySuccess(message)
    }else{
      handleClose()
      ToastNotifyError(message)
    }
  }
  return (
    <>
      <CTable hover responsive>
        <CTableHead className="text_style">
          <CTableRow style={{ textTransform: 'uppercase', backgroundColor: '#000000d4' }}>
            <CTableHeaderCell scope="col" width="5%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}></CTableHeaderCell>
            <CTableHeaderCell scope="col" width="15%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}>Name</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="20%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}>Email</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="20%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}>Expertise</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="20%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}>Services</CTableHeaderCell>
            <CTableHeaderCell scope="col" width="20%" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white', padding: '20px' }}>Sessions attended</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody className="text_style" style={{ fontSize: '12px' }}>
          {usersData &&
            usersData.map((item) => (
              <CTableRow key={item._id} style={{cursor: 'pointer'}}>
                <CTableDataCell width="5%" onClick={() => {
                  if (item?.isTicketCreated) {
                    handleClickOpen(item._id)
                  }
                }}>{item?.isTicketCreated && <img src={flag} alt="" style={{ width: '20px' }} />}</CTableDataCell>
                <CTableDataCell width="15%" onClick={() => navigate(`/users-detail?id=${item._id}`)}><span className='value-ellipsis'>{item.firstName ? item.firstName + ' ' + item.lastName : '-'}</span></CTableDataCell>
                <CTableDataCell width="20%" onClick={() => navigate(`/users-detail?id=${item._id}`)}><span className='value-ellipsis'>{item?.primaryEmail ? item?.primaryEmail : '-'}</span></CTableDataCell>
                <CTableDataCell width="20%" onClick={() => navigate(`/users-detail?id=${item._id}`)}><span className='value-ellipsis'>{item.expertise || '-'}</span></CTableDataCell>
                <CTableDataCell width="20%" onClick={() => navigate(`/users-detail?id=${item._id}`)}>{item.noOfServices || '-'}</CTableDataCell>
                <CTableDataCell width="20%" onClick={() => navigate(`/users-detail?id=${item._id}`)}>{item.noOfSessionsAttended || '-'}</CTableDataCell>
              </CTableRow>
            ))}
        </CTableBody>
      </CTable>
      <div className='table-paginate'>
        <CSmartPagination
          className="cursor_style"
          align="end"
          activePage={activePage}
          pages={totalPages}
          onActivePageChange={setActivePage}
        />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that this issue has been resolved?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={ticketFun}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserListingPage;

