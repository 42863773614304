/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLogout = createAsyncThunk('authentication/logout', async (data, { dispatch, getState }) => {
  const API = getAuthorization(getState);
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.LOGOUT, data);
    dispatch(updateLoading(false));
    localStorage.clear();
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchLogin = createAsyncThunk('authentication/login', async (data, { dispatch, getState }) => {
  const API = getAuthorization(getState);
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGIN, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

export {
  fetchLogin,
  fetchLogout,
};
