import {
  Navigate,
} from 'react-router-dom';
import { selectorAuthentication } from '../store/reducers/authentication';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
function PublicRoute({ children }) {
  const {
    auth: {
      data: {
        access_token = '',
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  return access_token ? <Navigate to="/dashboard" /> : children;
}

PublicRoute.prototype = {
  children: PropTypes.element,
};

export default PublicRoute;
