/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Chart from "chart.js/auto";
import {
  fetchDashBoardData,
  fetchDashBoardSession,
} from "../../store/reducers/userData/apiThunk";
import "./style.css";
import moment from "moment";
import CustomTable from "../../components/Table/CustomTable";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Dashboard = () => {
  const dispatch = useDispatch();
  // const [dashboardData, setDashboardData] = useState(null);
  const d = new Date();
  const [sessionReportChart, setSessionReportChart] = useState(null);
  const [signUpReportChart, setSignUpReportChart] = useState(null);
  const [type, setType] = useState("1");
  const [typeSign, setTypeSign] = useState("1");
  const [selectedMonth, setSelectedMonth] = useState(d.getMonth());
  const [selectedMonthSign, setSelectedMonthSign] = useState(d.getMonth());
  const [averageOfSessionReport, setAverageOfSessionReport] = useState(0);
  const [incompleteSignupCount, setIncompleteSignupCount] = useState(0);
  const [sessionReport, setSessionReport] = useState();
  const [signupReport, setSignupReport] = useState();
  const [reportType, setReportType] = useState(5);
  const [reportTypeSign, setReportTypeSign] = useState(5);
  const [sessions, setSessions] = useState([]);

  const navigate = useNavigate();

  const fetchDashBoardDetails = async () => {
    const requestData = {
      year: new Date().getFullYear(),
      month: type === "1" ? new Date().getMonth() + 1 : selectedMonth + 1,
      durationType: parseInt(type),
      reportType: reportType,
    };
    try {
      const dashboardDetails = await dispatch(fetchDashBoardData(requestData));
      const {
        payload: {
          data: {
            averageOfSessionReport,
            incompleteSignupCount,
            sessionReport,
            signupReport,
          },
        },
      } = dashboardDetails;
      // setDashboardData(data);
      if (averageOfSessionReport) {
        setAverageOfSessionReport(averageOfSessionReport);
      }

      if (incompleteSignupCount) {
        setIncompleteSignupCount(incompleteSignupCount);
      }

      if (sessionReport) {
        setSessionReport(sessionReport);
      }

      if (signupReport) {
        setSignupReport(signupReport);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchDashBoardDetailsSign = async () => {
    const requestData = {
      year: new Date().getFullYear(),
      month:
        typeSign === "1" ? new Date().getMonth() + 1 : selectedMonthSign + 1,
      durationType: parseInt(typeSign),
      reportType: reportTypeSign,
    };
    try {
      const dashboardDetails = await dispatch(fetchDashBoardData(requestData));
      const {
        payload: {
          data: {
            averageOfSessionReport,
            incompleteSignupCount,
            sessionReport,
            signupReport,
          },
        },
      } = dashboardDetails;
      // setDashboardData(data);
      if (averageOfSessionReport) {
        setAverageOfSessionReport(averageOfSessionReport);
      }

      if (incompleteSignupCount) {
        setIncompleteSignupCount(incompleteSignupCount);
      }

      if (sessionReport) {
        setSessionReport(sessionReport);
      }

      if (signupReport) {
        setSignupReport(signupReport);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };
  const fetchDashBoardSessionList = async () => {
    const requestData = `${`?page=1&limit=5`}`;
    try {
      const dashboardDetails = await dispatch(
        fetchDashBoardSession(requestData)
      );
      const {
        payload: {
          data: { data = {} },
          status = "",
        },
      } = dashboardDetails;
      if (status) {
        setSessions(data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchDashBoardSessionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDashBoardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selectedMonth]);

  useEffect(() => {
    fetchDashBoardDetailsSign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeSign, selectedMonthSign]);

  useEffect(() => {
    if (sessionReport) {
      // Call functions to create the bar graphs after the data is available
      createSessionReportGraph(sessionReport);
      // createSignupReportGraph(dashboardData["Signup Report"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionReport]);

  useEffect(() => {
    if (signupReport) {
      createSignupReportGraph(signupReport);
    }
  }, [signupReport]);

  const createSessionReportGraph = (sessionReportData) => {
    // Extract the data for the bar graph and fill missing _id values with zero data
    const labels = [];
    const data = [];

    if (type === "1") {
      // Monthly (Jan-Dec)
      for (let i = 1; i <= 12; i++) {
        const monthData = sessionReportData.data.find((item) => item._id === i);
        if (monthData) {
          labels.push(monthNames[i - 1]);
          data.push(monthData.documentCount);
        } else {
          labels.push(monthNames[i - 1]);
          data.push(0);
        }
      }
    } else if (type === "2") {
      // Weekly (Week1 , Week 2 ... Week4/5)
      const firstDayOfMonth = moment(
        `${new Date().getFullYear()}-${selectedMonth + 1}-01`,
        "YYYY-MM-DD"
      );

      const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
      const numWeeks = lastDayOfMonth.week() - firstDayOfMonth.week() + 1;
      for (let i = 1; i <= numWeeks; i++) {
        const weekData = sessionReportData.data.find((item) => item._id === i);
        if (weekData) {
          labels.push(`Week ${i}`);
          data.push(weekData.documentCount);
        } else {
          labels.push(`Week ${i}`);
          data.push(0);
        }
      }
    } else {
      // Daily (Day1, Day2 .... Day30/31)
      const daysInMonth = new Date(
        new Date().getFullYear(),
        selectedMonth,
        0
      ).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const dayData = sessionReportData.data.find((item) => item._id === i);
        if (dayData) {
          labels.push(`Day ${i}`);
          data.push(dayData.documentCount);
        } else {
          labels.push(`Day ${i}`);
          data.push(0);
        }
      }
    }

    // Check if the chartCanvas element exists before creating the Chart
    const chartCanvas = document.getElementById("sessionReportChart");
    if (chartCanvas) {
      // Destroy the existing Chart instance, if any
      if (sessionReportChart) {
        sessionReportChart.destroy();
      }

      // Create the chart
      const newChart = new Chart(chartCanvas, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Sessions Attended",
              data: data,
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      // Save the Chart instance to state
      setSessionReportChart(newChart);
    }
  };

  const createSignupReportGraph = (signupReportData) => {
    const labels = [];
    const data = [];

    if (typeSign === "1") {
      // Monthly (Jan-Dec)
      for (let i = 1; i <= 12; i++) {
        const monthData = signupReportData.data.find((item) => item._id === i);
        if (monthData) {
          labels.push(monthNames[i - 1]);
          data.push(monthData.documentCount);
        } else {
          labels.push(monthNames[i - 1]);
          data.push(0);
        }
      }
    } else if (typeSign === "2") {
      // Weekly (Week1 , Week 2 ... Week4/5)
      const firstDayOfMonth = moment(
        `${new Date().getFullYear()}-${selectedMonthSign + 1}-01`,
        "YYYY-MM-DD"
      );

      const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
      const numWeeks = lastDayOfMonth.week() - firstDayOfMonth.week() + 1;
      for (let i = 1; i <= numWeeks; i++) {
        const weekData = signupReportData.data.find((item) => item._id === i);
        if (weekData) {
          labels.push(`Week ${i}`);
          data.push(weekData.documentCount);
        } else {
          labels.push(`Week ${i}`);
          data.push(0);
        }
      }
    } else {
      // Daily (Day1, Day2 .... Day30/31)
      const daysInMonth = new Date(
        new Date().getFullYear(),
        selectedMonthSign,
        0
      ).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const dayData = signupReportData.data.find((item) => item._id === i);
        if (dayData) {
          labels.push(`Day ${i}`);
          data.push(dayData.documentCount);
        } else {
          labels.push(`Day ${i}`);
          data.push(0);
        }
      }
    }

    // Check if the chartCanvas element exists before creating the Chart
    const chartCanvas = document.getElementById("signUpReportChart");
    if (chartCanvas) {
      // Destroy the existing Chart instance, if any
      if (signUpReportChart) {
        signUpReportChart.destroy();
      }
      // Create the chart
      const newChart = new Chart(chartCanvas, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Onboarded Experts",
              data: data,
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });

      // Save the Chart instance to state
      setSignUpReportChart(newChart);
    }
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
    setReportType(1);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
    setReportType(1);
  };

  const handleTypeChangeSign = (event) => {
    setTypeSign(event.target.value);
    setReportTypeSign(2);
  };

  const handleMonthChangeSign = (event) => {
    setSelectedMonthSign(parseInt(event.target.value));
    setReportTypeSign(2);
  };

  return (
    <div className="dashboard-container">
      <div className="row-container">
        <div className="section-box">
          {/* Section 3: Tile for Incomplete Signup Report */}
          <h4>Incomplete Signup</h4>
          <div className="tile-container">
            {incompleteSignupCount && <h4>{incompleteSignupCount || 0}</h4>}
          </div>
          {/* <div className="chart-container">
          <canvas id="incompleteSignUpReportChart" width="400" height="200"></canvas>
        </div> */}
        </div>
        <div className="section-box">
          {/* Section 4: Tile for Average Of Session Report */}
          <h4>Average Monthly Sessions</h4>
          <div className="tile-container">
            {averageOfSessionReport && <h4>{averageOfSessionReport || 0}</h4>}
          </div>
        </div>
      </div>
      <div className="row-container">
        <div className="section-box">
          {/* Section 1: Bar Graph for Session Report */}
          <h4>Sessions Attended</h4>
          <div className="dropdown-container">
            <select onChange={handleTypeChange} className="dropdown-select">
              <option value="1">Monthly</option>
              <option value="2">Weekly</option>
              <option value="3">Daily</option>
              {/* <option value="4">Hourly</option> */}
            </select>

            {type && type !== "1" && (
              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                className="dropdown-select ml-10"
              >
                {monthNames.map((el, index) => {
                  return <option value={index}>{el}</option>;
                })}
              </select>
            )}
          </div>
          <div className="chart-container">
            <canvas id="sessionReportChart" width="400" height="200"></canvas>
          </div>
        </div>

        <div className="section-box">
          {/* Section 2: Bar Graph for Signup Report */}
          <h4>Onboarded Experts</h4>
          <div className="dropdown-container">
            <select onChange={handleTypeChangeSign} className="dropdown-select">
              <option value="1">Monthly</option>
              <option value="2">Weekly</option>
              <option value="3">Daily</option>
            </select>

            {typeSign && typeSign !== "1" && (
              <select
                value={selectedMonthSign}
                onChange={handleMonthChangeSign}
                className="dropdown-select ml-10"
              >
                {monthNames.map((el, index) => {
                  return <option value={index}>{el}</option>;
                })}
              </select>
            )}
          </div>
          <div className="chart-container">
            <canvas id="signUpReportChart" width="400" height="200"></canvas>
          </div>
        </div>
      </div>

      <div className="row-container">
        <CustomTable
          sessionsData={sessions}
          tableHeaderRight={
            <>
              <Button
                variant="text"
                className="tableButton"
                onClick={() => navigate(ROUTE_PATH.SESSIONS)}
              >
                View All
              </Button>
            </>
          }
        />
      </div>
    </div>
  );
};

export default Dashboard;
