import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Chip,
  MenuItem,
  Select,
  Box,
  FormControl,
  styled,
} from "@mui/material";
import "./CustomTable.scss";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "../Modal";
import { useDispatch } from "react-redux";
import {  updatePaymentSession } from "../../store/reducers/userData/apiThunk";
import { ToastNotifySuccess } from "../Toast/ToastNotify";

export const SESSION_STATUS_ENUM = {
  PENDING: 1,
  IN_PROGRESS: 2,
  UPCOMING: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  RACE: 6,
};

// Map numeric values to status strings
const STATUS_MAP = {
  [SESSION_STATUS_ENUM.PENDING]: "PENDING",
  [SESSION_STATUS_ENUM.IN_PROGRESS]: "IN_PROGRESS",
  [SESSION_STATUS_ENUM.UPCOMING]: "UPCOMING",
  [SESSION_STATUS_ENUM.COMPLETED]: "COMPLETED",
  [SESSION_STATUS_ENUM.CANCELLED]: "CANCELLED",
  [SESSION_STATUS_ENUM.RACE]: "RACE",
};

export const getStatusChip = (status) => {
  const statusString = STATUS_MAP[status]; // Convert numeric status to string

  switch (statusString) {
    case "COMPLETED":
      return (
        <Chip label="COMPLETED" color="success" className="completedChip" />
      );
    case "CANCELLED":
      return <Chip label="CANCELLED" color="error" className="cancelledChip" />;
    case 'UPCOMING':
      return <Chip label="UPCOMING" color="error" className="pendingChip" />;
    default:
      return <Chip label={statusString || status} />; // Handle other statuses or invalid values
  }
};

const getMenuItems = (status, expertPaymentStatus) => {
  switch (status) {
    case SESSION_STATUS_ENUM.UPCOMING:
      return [
        <MenuItem key="1" value={SESSION_STATUS_ENUM.UPCOMING}>--</MenuItem>,
      ];
    case SESSION_STATUS_ENUM.CANCELLED:
      return [
        <MenuItem key="2" value={SESSION_STATUS_ENUM.CANCELLED}>Refunded</MenuItem>,
      ];
    case SESSION_STATUS_ENUM.COMPLETED:
      return [
      <MenuItem
        key="6"
        value={4}
        selected={status===4}
        disabled
      >
       Select
      </MenuItem>,
      <MenuItem
          key="3"
          value="initiated"
          selected={expertPaymentStatus === "initiated"} 
        >
          Initiated
      </MenuItem>,
      <MenuItem
          key="4"
          value="paid"
          selected={expertPaymentStatus === "paid"} 
        >
          Paid
      </MenuItem>,
      ];
    default:
      return
  }
};

const CustomSelect = styled(Select)(({ theme }) => ({
  borderRadius: "6px",
  border: "1px solid #d9dde3",
  "& .MuiSelect-select": {
    padding: "8px 12px",
    fontSize: "16px",
    color: "#2d3748",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover": {
    borderColor: "#2d3748",
  },
  "&.Mui-disabled": {
    "&:hover":{
    borderColor:'#d9dde3',}
  },
  "& .MuiSelect-icon": {
    color: "#a0aec0",
    right: "10px",
  },
}));

export default function CustomTable({
  sessionsData = [],
  tableHeaderRight = false,
  fetchSession,
}) {
  const dispatch = useDispatch();
  const [viewDetailOpen, setViewDetailOpen] = useState(false);
  const [viewAmountOpen, setViewAmountOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [amountDetails, setAmountDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const disableScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
      }
    };
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('wheel', disableScroll, { passive: false });
    } else {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', disableScroll);
    }

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', disableScroll);
    };
  }, [isOpen]);

  const handleChange = (e,id) => {
    const value= e.target.value
    handlePaymentStatus(id,value)
  };
  const handleCloseDetails = () => {
    setViewDetailOpen(false)
    setBankDetails({})
  };
  const handleCloseAmountDetails = () => {
    setViewAmountOpen(false)
    setAmountDetails({})
  };
  const handlePaymentStatus = async (id,paymentStatus) => {
    const dataTosend = {
      expertPaymentStatus: paymentStatus,
    };
    const data = {
      id,
      dataTosend,
    };
    try {
      const response = await dispatch(updatePaymentSession(data));
      const {payload :{status='',message=''}={}} =response
      if(status){
        ToastNotifySuccess(message)
        fetchSession()
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  return (
    <>
      <Box sx={{ padding: 3 }} className="customTableWrapper">
        <TableContainer component={Paper}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
            className="table-header-wrap"
          >
            <Typography variant="h6">Expert's Sessions</Typography>
            {tableHeaderRight ? tableHeaderRight : ""}
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Expert Name & Email</TableCell>
                <TableCell>Session Name</TableCell>
                <TableCell>Date & Time</TableCell>
                <TableCell>Session Amount</TableCell>
                <TableCell>Session Status</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Bank Account</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessionsData.length ? sessionsData?.map((session, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant="body1">
                      {session.expert.firstName + ' ' + session.expert.lastName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {session.expert.primaryEmail}
                    </Typography>
                  </TableCell>
                  <TableCell>{session.title}</TableCell>
                  <TableCell>
                    {dayjs(session.eventStartTime.dateTime).format(
                      "MMM DD, YYYY hh:mm A"
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="primary"
                      className="tableButton"
                      onClick={()=>{
                        setAmountDetails(session?.paymentStructure)
                        setViewAmountOpen(true)
                      }}
                    
                    >
                      View Amount
                    </Button>
                  </TableCell>
                  <TableCell>{getStatusChip(session.status)}</TableCell>
                  <TableCell>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <CustomSelect
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        value={session.expertPaymentStatus ?? session.status}
                        onChange={(e)=>handleChange(e,session._id)}
                        displayEmpty
                        IconComponent={ExpandMoreIcon}
                        disabled={session.status === SESSION_STATUS_ENUM.CANCELLED || session.status === SESSION_STATUS_ENUM.UPCOMING  || session.expertPaymentStatus==='paid' }
                      >
                       {getMenuItems(session.status,session.expertPaymentStatus)}
                      </CustomSelect>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      color="primary"
                      className="tableButton"
                      onClick={()=>{
                        setBankDetails(session?.bankDetails)
                        setViewDetailOpen(true)
                      }}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              )):null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        openDialog={viewDetailOpen}
        closeDialog={handleCloseDetails}
        heading={<div className="title">Bank Account Details</div>}
        dialogContent={
          <div className="content-wrapper">
            <div className="wrap-div">
              <div className="heading-title">Account Type</div>
              <div className="desc">{bankDetails?.accountType ??'N/A'} account</div>
            </div>
            <div className="wrap-div">
              <div className="heading-title">Account Holder Name</div>
              <div className="desc">{bankDetails?.accountHolderName ??'N/A'}</div>
            </div>
            <div className="wrap-div">
              <div className="heading-title">IFSC Code</div>
              <div className="desc">{bankDetails?.ifscCode??'N/A'}</div>
            </div>
            <div className="wrap-div">
              <div className="heading-title">Account Number</div>
              <div className="desc">{bankDetails?.accountNumber??'N/A'}</div>
            </div>
          </div>
        }
        closeButVisible
        dialogCss="accountDetail"
      />
        <Modal
        openDialog={viewAmountOpen}
        closeDialog={handleCloseAmountDetails}
        heading={<div className="title">Amount Breakdown</div>}
        dialogContent=
          {amountDetails?.seekerPayout?
            <>
            <div className="content-wrapper-amount">
              <div className="wrap-div-amount">
                <div className="heading-title">Payment Received from seeker <br/>(After Taxes Deduction)</div>
                <div className="desc">{amountDetails?.payReceivedFromSeeker??'N/A'}</div>
              </div>
              <div className="wrap-div-amount">
                <div className="heading-title">GST & Transaction Fees Deducted<br/>(To be Included by admin) </div>
                <div className="desc">{amountDetails?.paymentGatewayFees??'N/A'}</div>
              </div>
              <div className="wrap-div-amount">
                <div className="heading-title">Total Session amount</div>
                <div className="desc">{amountDetails?.totalAmountOfSession??'N/A'}</div>
              </div>
            </div>
            <div className="total-amount-wrapper">
              <div>
                <div className="title-amount">Total amount to the Seeker</div>
                <div className="desc">(Adding Tax Deductions)</div>
              </div>
              <div className="totalAmount">₹{amountDetails?.seekerPayout??'N/A'}</div>
            </div>
            </>:
          <>
            <div className="content-wrapper-amount">
              <div className="wrap-div-amount">
                <div className="heading-title">Total Amount of the session</div>
                <div className="desc">{amountDetails?.totalAmountOfSession??'N/A'}</div>
              </div>
              <div className="wrap-div-amount">
                <div className="heading-title">GST & Transaction fee </div>
                <div className="desc">{amountDetails?.paymentGatewayFees??'N/A'}</div>
              </div>
              <div className="wrap-div-amount">
                <div className="heading-title">SC platform fee</div>
                <div className="desc">{amountDetails?.superconnectPlatformFees??'N/A'}</div>
              </div>
            </div>
            <div className="total-amount-wrapper">
              <div>
                <div className="title-amount">Total amount to the expert</div>
                <div className="desc">(After Tax & Platform Fee Deductions)</div>
              </div>
              <div className="totalAmount">₹{amountDetails?.expertPayout??'N/A'}</div>
            </div>
            </>
            }
        closeButVisible
        dialogCss="accountDetail"
      />
    </>
  );
}
