/* eslint-disable consistent-return */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthorization } from "../../../services/api";
import { API_PATH } from "../../../constants/route";
import { updateLoading } from "../loader";

const fetchDashBoardData = createAsyncThunk(
  "userData/dashboardData",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.DASHBOARD, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchExpertList = createAsyncThunk(
  "userData/expertList",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(
        API_PATH.EXPERTLIST + `?limit=10&page=${data.page}`
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchExpertDetail = createAsyncThunk(
  "userData/expertList",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(
        API_PATH.EXPERTDETAIL + data.id,
        data.data
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchExpertIssueResolved = createAsyncThunk(
  "userData/ExpertIssueResolved",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(
        API_PATH.EXPERTTICKETRESOLVED + data.id,
        {}
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

const fetchDashBoardSession = createAsyncThunk(
  "userData/dashboardSession",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.SESSIONLIST + data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);
const updatePaymentSession = createAsyncThunk(
  "userData/updatePaymentSession",
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(
        API_PATH.PAYMENTSESSION + data.id,
        data.dataTosend
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  }
);

export {
  fetchDashBoardData,
  fetchExpertList,
  fetchExpertDetail,
  fetchExpertIssueResolved,
  fetchDashBoardSession,
  updatePaymentSession,
};
