import React, { useState } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
// import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
// import 'simplebar/dist/simplebar.min.css';
// import '../scss/AppSidebar.css';

import navigation from "../_nav";

import logo from "../assets/svg/icon.svg";
// import { set } from '../store/auth/action';
import { AppSidebarNav } from "./AppSidebarNav";
import "./style.css";
const AppSidebar = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { sidebarShow } = useSelector((state) => state.changeState);
  const [unFoldable, setUnFoldable] = useState(true);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unFoldable}
      className="bg-sidebar"
      // visible={sidebarShow}
      // onVisibleChange={(visible) => {
      //   sidebarShow &&
      //     dispatch(
      //       set({
      //         sidebarShow: visible,
      //       }),
      //     );
      // }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <div className="sidebar_heading">
          <div>
            <img
              onClick={() =>
                navigate("/dashboard", {
                  replace: true,
                })
              }
              alt="logo"
              src={logo}
              style={{
                width: "40px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            ></img>
          </div>
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => setUnFoldable(!unFoldable)}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
