/* eslint-disable import/prefer-default-export */
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1AA262',
    },
    secondary: {
      main: '#fff',
    },
    customColor: {
      primaryWhite: '#FFFFFF',
      secondaryWhite: '#F2F2F2',
      tertiaryWhite: '#EDEDED',
      primaryBlack: '#060606',
      secondaryBlack: '#0B0B0B',
      tertiaryBlack: '#0A0A0A',
      primaryGrey: '#3D3D3D',
      secondaryGrey: '#1E1E1E',
      primaryLightGray: '#B4B4B4',
      secondaryLightGray: '#242424',
      secondaryGreyText: '#A6A6A6',
      tertiaryGreyText: '#A1A1A1',
      primaryYellow: '#F69824',
      primaryTransparent: 'transparent',
      secondaryTwo: '#EAEAEA',
      secondaryThree: '#3F3F3F',
      secondaryFour: '#262626',
      stateLinks: '#63BDFF',
      stateError: '#DA3333',
      secondayLinks: '#1976D2',
      black: '#000',
      blackLight: '#BBBBBB',
      link_1: '#1F90FF',
      black_1: '#D9D9D9',
      black_2: '#1E1E1E',
      black_3: '#282828',
      black_4: '#eaeaf0',
      white_1: '#F1F6F9',
      white_2: '#EBEBEE',
      grey_1: '#CECECE',
      grey_2: '#979797',
      primaryGradient: 'linear-gradient(140.56deg, #1E1E1E 22.57%, #000000 79.47%)',
      secondaryGradient: 'background: linear-gradient(103.17deg, rgba(30, 30, 30, 0) 38.02%, #1C1C1C 106.59%)',
      link_color: '#1F90FF',
    },
    boxShadow: {
      main: '0px 7px 10px rgba(40, 41, 61, 0.08)',
    },
  },
});
