import React, { useEffect, useState } from "react";
// import Dialog from '@mui/material/Dialog';
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledModal from "./StyledModal";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({
  openDialog,
  closeDialog,
  noHeader,
  dialogCss,
  heading,
  dialogContent,
  dialogAction,
  closeButVisible,
}) => {
  const [open, setOpen] = useState(openDialog);
  useEffect(() => {
    if (openDialog) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openDialog]);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    closeDialog();
    setOpen(false);
  };

  return (
    <StyledModal
      open={open}
      disableBackdropClick="true"
      className={`${dialogCss} dialogBox`}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!noHeader && (
        <DialogTitle id="alert-dialog-title">
          <div className="dialog-title">{heading}</div>
          <div onClick={handleClose} className="crossIcon" role="presentation">
            {closeButVisible ? (
              <CloseIcon
                style={{ color: "#212B36", cursor: "pointer" }}
                onClick={handleClose}
                role="presentation"
              />
            ) : (
              ""
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent>{dialogContent}</DialogContent>
      {dialogAction}
    </StyledModal>
  );
};

export default Modal;
