/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import AppHeader from '../../components/AppHeader'
import AppSidebar from '../../components/AppSidebar'
import { fetchExpertDetail } from '../../store/reducers/userData/apiThunk'
import Chart from 'chart.js/auto';
import './style.css'
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const UserDetail = () => {
    const [userData, setUserData] = useState({})
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [type, setType] = useState("1");
    const d = new Date();
    const [selectedMonth, setSelectedMonth] = useState(d.getMonth());
    const [sessionReportChart, setSessionReportChart] = useState(null);
    const [sessionReport, setSessionReport] = useState();
    const userDetail = async () => {
        const requestData = {
            "id": id,
            data: {
                "year": new Date().getFullYear(),
                "month": type === "1" ? new Date().getMonth() + 1 : selectedMonth + 1,
                "durationType": parseInt(type),
                "reportType": 1
            }
        }
        const data = await dispatch(fetchExpertDetail(requestData));
        const { payload: { data: { sessionReport, expertDetails } } } = data;
        if (sessionReport) {
            setSessionReport(sessionReport);
            setUserData(expertDetails)
        } else {
            console.error("Error fetching dashboard data:");
        }

    }

    useEffect(() => {
        userDetail();
    }, [type, selectedMonth]);

    useEffect(() => {
        if (sessionReport) {
            // Call functions to create the bar graphs after the data is available
            createSessionReportGraph(sessionReport);
            // createSignupReportGraph(dashboardData["Signup Report"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionReport]);

    const createSessionReportGraph = (sessionReportData) => {
        // Extract the data for the bar graph and fill missing _id values with zero data
        const labels = [];
        const data = [];

        if (type === "1") {
            // Monthly (Jan-Dec)
            for (let i = 1; i <= 12; i++) {
                const monthData = sessionReportData.data.find(item => item._id === i);
                if (monthData) {
                    labels.push(monthNames[i - 1]);
                    data.push(monthData.documentCount);
                } else {
                    labels.push(monthNames[i - 1]);
                    data.push(0);
                }
            }
        } else if (type === "2") {
            // Weekly (Week1 , Week 2 ... Week4/5)
            const firstDayOfMonth = moment(`${new Date().getFullYear()}-${selectedMonth + 1}-01`, 'YYYY-MM-DD');
            const lastDayOfMonth = firstDayOfMonth.clone().endOf('month');
            const numWeeks = lastDayOfMonth.week() - firstDayOfMonth.week() + 1;
            for (let i = 1; i <= numWeeks; i++) {
                const weekData = sessionReportData.data.find(item => item._id === i);
                if (weekData) {
                    labels.push(`Week ${i}`);
                    data.push(weekData.documentCount);
                } else {
                    labels.push(`Week ${i}`);
                    data.push(0);
                }
            }

        } else {
            // Daily (Day1, Day2 .... Day30/31)
            const daysInMonth = new Date(new Date().getFullYear(), selectedMonth, 0).getDate();

            for (let i = 1; i <= daysInMonth; i++) {
                const dayData = sessionReportData.data.find(item => item._id === i);
                if (dayData) {
                    labels.push(`Day ${i}`);
                    data.push(dayData.documentCount);
                } else {
                    labels.push(`Day ${i}`);
                    data.push(0);
                }
            }
        }


        // Check if the chartCanvas element exists before creating the Chart
        const chartCanvas = document.getElementById('sessionReportChart');
        if (chartCanvas) {
            // Destroy the existing Chart instance, if any
            if (sessionReportChart) {
                sessionReportChart.destroy();
            }

            // Create the chart
            const newChart = new Chart(chartCanvas, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Sessions Attended',
                        data: data,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            });

            // Save the Chart instance to state
            setSessionReportChart(newChart);
        }
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(parseInt(event.target.value));
    };
    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader text={userData?.firstName ? userData?.firstName + ' ' + userData?.lastName : 'User Detail' } arrow={true}/>
                <div className='userdetail' style={{marginTop: '30px'}}>
                    Name: <span style={{color: '#000000d4', fontWeight: 'bold'}}>{userData?.firstName ? userData?.firstName + ' ' + userData?.lastName : '-' }</span>
                </div>
                <div className='userdetail'>
                    Email: <span style={{color: '#000000d4', fontWeight: 'bold'}}>{userData?.primaryEmail ? userData?.primaryEmail : '-' }</span>
                </div >
                <div className='userdetail'>
                    Expertise: <span style={{color: '#000000d4', fontWeight: 'bold'}}>{userData?.expertise ? userData?.expertise : '-' }</span>
                </div>
                <div className='session-user'>
                    {/* Section 1: Bar Graph for Session Report */}
                    <h4>Sessions Attended</h4>
                    <div className="dropdown-container">
                        <select onChange={handleTypeChange} className='dropdown-select'>
                            <option value="1">Monthly</option>
                            <option value="2">Weekly</option>
                            <option value="3">Daily</option>
                            {/* <option value="4">Hourly</option> */}
                        </select>

                        {type && type !== "1" && <select value={selectedMonth} onChange={handleMonthChange} className='dropdown-select ml-10'>
                            {monthNames.map((el, index) => {
                                return <option value={index}>{el}</option>
                            })}
                        </select>}
                    </div>
                    <div className="chart-container">
                        <canvas id="sessionReportChart" width="400" height="200"></canvas>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UserDetail