import React, { useEffect, useState } from "react";
import AppSidebar from "../../components/AppSidebar";
import AppHeader from "../../components/AppHeader";
import CustomTable from "../../components/Table/CustomTable";
import "./sessions.scss";
import {
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/route";
import { useDispatch } from "react-redux";
import { fetchDashBoardSession } from "../../store/reducers/userData/apiThunk";
import { CSmartPagination } from "@coreui/react-pro";
import { FormControl } from "@mui/base";

const CustomSelect = styled(Select)(({ theme }) => ({
  background: "white",
  borderRadius: "6px",
  border: "1px solid #d9dde3",
  "& .MuiSelect-select": {
    padding: "8px 12px",
    fontSize: "16px",
    color: "#2d3748",
    textAlign: "left",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover": {
    borderColor: "#2d3748",
  },
  "& .MuiSelect-icon": {
    color: "#a0aec0",
    right: "10px",
  },
}));


const CustomSearch = styled(TextField)(({ theme }) => ({
  width:'260px',
  background: "white",
  borderRadius: "6px",
  border: "1px solid #d9dde3",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    height: "6px",
  },
}));

export default function Sessions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [sessionsType, setSessionsType] = useState('all');
  const [search, setSearch] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    const disableScroll = (e) => {
      if (isOpen) {
        e.preventDefault();
      }
    };
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.addEventListener('wheel', disableScroll, { passive: false });
    } else {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', disableScroll);
    }

    return () => {
      document.body.style.overflow = 'unset';
      window.removeEventListener('wheel', disableScroll);
    };
  }, [isOpen]);

  const handleChange = (e) => {
    setSessionsType(e.target.value);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fetchDashBoardSessionList = async () => {
    const requestData = `${`?search=${debouncedSearch}&filter=${sessionsType}&page=${activePage}&limit=10`}`;
    try {
      const dashboardDetails = await dispatch(
        fetchDashBoardSession(requestData)
      );
      const {
        payload: { data :{page=1,data={}}={} ,status = "" }} = dashboardDetails;
   
      if (status) {
        setSessions(data);
        setTotalPages(page);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

   // Debouncing the search input by 400ms
   useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 400);

    // Cleanup timeout if the component unmounts or search changes
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (debouncedSearch) {
      setActivePage(1); // Reset page to 1 when search changes
    }
    fetchDashBoardSessionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, sessionsType, activePage]);

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader text="DASHBOARD" />
          <div className="body flex-grow-1 px-3">
            <div className="sessions-container">
              <div
                className="backArrow"
                onClick={() => navigate(ROUTE_PATH.DASHBOARD)}
              >
                <ArrowBackIcon />
                Go back
              </div>
              <CustomTable
              sessionsData={sessions}
              fetchSession={()=>fetchDashBoardSessionList()}
                tableHeaderRight={
                  <div className="wrapper-header">
                    <CustomSearch
                      variant="outlined"
                      placeholder="Search By Name, Email"
                      value={search}
                      onChange={(e)=>handleSearch(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}

                    />
                     <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <CustomSelect
                      onOpen={() => setIsOpen(true)}
                      onClose={() => setIsOpen(false)}
                      value={sessionsType}
                      onChange={handleChange}
                      IconComponent={ExpandMoreIcon}
                      sx={{ minWidth: 180 }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="all">All sessions</MenuItem>
                      <MenuItem value="upcoming">Upcoming sessions</MenuItem>
                      <MenuItem value="completed">Completed sessions</MenuItem>
                      <MenuItem value="cancelled">Cancelled sessions</MenuItem>
                      <MenuItem value="paymentcompleted">Payment Completed</MenuItem>
                    </CustomSelect>
                    </FormControl>
                  </div>
                }
              />
            </div>
            <div className='table-paginate'>
            <CSmartPagination
              className="cursor_style"
              align="end"
              activePage={activePage}
              pages={totalPages}
              onActivePageChange={setActivePage}
            />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
