const ROUTE_PATH = {
  PAGE404: "*",
  LANDING: "/",
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_DETAIL: "/users-detail",
  SESSIONS: "/sessions",
};

const API_VERSION = {
  V1: "/api/app/v1",
};

const API_PATH = {
  LOGIN: `${API_VERSION.V1}/admin/login`,
  LOGOUT: `${API_VERSION.V1}/admin/logout`,
  DASHBOARD: `${API_VERSION.V1}/admin/dashboard-details`,
  EXPERTLIST: `${API_VERSION.V1}/admin/expert-list`,
  EXPERTDETAIL: `${API_VERSION.V1}/admin/expert-activity-report/`,
  EXPERTTICKETRESOLVED: `${API_VERSION.V1}/admin/resolve-ticket/`,
  SESSIONLIST: `${API_VERSION.V1}/admin/upcoming-sessions`,
  PAYMENTSESSION: `${API_VERSION.V1}/admin/expert-payment-status/`,
};

export { ROUTE_PATH, API_PATH };
