import { useSelector } from 'react-redux';
import {
  Navigate,
} from 'react-router-dom';
import { selectorAuthentication } from '../store/reducers/authentication';
import PropTypes from 'prop-types';
function PrivateRoute({ children }) {
  const {
    auth: {
      data: {
        access_token = '',
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  return access_token
    ? children
    : <Navigate to="/" />;
}

PrivateRoute.prototype = {
  children: PropTypes.element,
};

export default PrivateRoute;
