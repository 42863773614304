import { styled } from "@mui/material/styles";

const StyledLoader = styled("div")(
  () => `
.backdrop{
    color: {};
    z-ndex: 99999999;
    .loader{
        color: #1AA262;
    }
}
`
);

export default StyledLoader;
