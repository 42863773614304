import React, { useState } from 'react';
// import { Toast } from '@coreui/coreui';
import { cilLockLocked, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import { REGEX } from '../../constants/constants';
import { fetchLogin } from '../../store/reducers/authentication/apiThunk';
import { useDispatch } from 'react-redux';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route';
import './style.css';
const { DASHBOARD } = ROUTE_PATH;

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  

  const handleSubmit = async (event) => {
    if (
      email.match(REGEX.EMAIL_PATTERN) && password
    ) {
      event.preventDefault();
      setValidated(false);
      const requestData = {
        "email": email,
        "password": password
      }
      const data = await dispatch(fetchLogin(requestData));
      const {payload} = data
      if(payload?.status){
        navigate(DASHBOARD);
      }
    }else{
      ToastNotifyError('Please enter login credentials');
    }
  };

  return (
    <div className="bg-color min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={5} md={8} sm={12} xs={12}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit}>
                    <h1 style={{fontWeight: 'bold', color: 'black'}}>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3 emailField" >
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        defaultValue={null}
                        feedbackValid="Looks good!"
                        id="validationCustom01"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        id="inputPassword"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={12}>
                        <CButton 
                          color="primary" 
                          className="px-4" 
                          type="submit" 
                          style={{background: '#415DF3', border: '1px solid #415DF3'}}
                          disabled={!email.match(REGEX.EMAIL_PATTERN) || !password}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
