import React from "react";
import { CBadge } from "@coreui/react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

export const AppSidebarNav = ({ items }) => {
  const location = useLocation();

  // Function to render nav link with optional icon and badge
  const navLink = (name, icon, badge) => (
    <>
      {icon && <span>{icon}</span>}
      {name && <span style={{ marginLeft: "10px" }}>{name}</span>}
      {badge && (
        <CBadge color={badge.color} className="ms-auto navBar_view">
          {badge.text}
        </CBadge>
      )}
    </>
  );

  // Function to render a single navigation item
  const navItem = (item, index) => {
    const { component: Component, name, badge, icon, to, ...rest } = item;

    // Directly use NavLink when 'to' is provided, otherwise fall back to custom component
    return to ? (
      <li key={index} className="nav-item">
        <NavLink to={to} className="nav-link" {...rest}>
          {navLink(name, icon, badge)}
        </NavLink>
      </li>
    ) : (
      <Component key={index} {...rest}>
        {navLink(name, icon, badge)}
      </Component>
    );
  };

  // Function to render navigation groups (nested items)
  const navGroup = (item, index) => {
    const { component: Component, name, icon, to, items, ...rest } = item;

    return (
      <Component
        key={index}
        idx={String(index)}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {items?.map((childItem, childIndex) =>
          childItem.items
            ? navGroup(childItem, childIndex)
            : navItem(childItem, childIndex)
        )}
      </Component>
    );
  };

  // Render the entire navigation structure
  return (
    <>
      {items.map((item, index) =>
        item.items ? navGroup(item, index) : navItem(item, index)
      )}
    </>
  );
};

// Prop types validation
AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.elementType.isRequired, // Ensure component is a valid React element type
      name: PropTypes.string,
      badge: PropTypes.shape({
        color: PropTypes.string,
        text: PropTypes.string,
      }),
      icon: PropTypes.node,
      to: PropTypes.string,
      items: PropTypes.array,
    })
  ).isRequired,
};

export default AppSidebarNav;
