import React from 'react';
// import { cilMenu } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler } from '@coreui/react';
// import { useSelector, useDispatch } from 'react-redux';

import logo from '../assets/svg/logo.svg';
// import { set } from '../store/auth/action';
import { AppHeaderDropdown } from './header/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.css';
import { useNavigate } from 'react-router-dom';

const AppHeader = ({ text, arrow }) => {
  // const dispatch = useDispatch();
  // const { sidebarShow } = useSelector((state) => state.changeState);
  const navigate = useNavigate()
  return (
    <CHeader position="sticky" style={{backgroundColor: 'black'}}>
      <CContainer fluid>
        {
          arrow ? <CHeaderToggler
          className="ps-1"
          onClick={() =>{
            navigate(-1)
          }
        }
        >
        <ArrowBackIcon style={{color: 'white'}}/>
          {/* <CIcon icon={cilMenu} size="lg" style={{color: 'white'}} /> */}
        </CHeaderToggler> : <CHeaderToggler
          className="ps-1"
        >
          {/* <CIcon icon={cilMenu} size="lg" style={{color: 'white'}} /> */}
        </CHeaderToggler>
        }
        
        <span
          style={{
            marginLeft: '40px',
            position: 'absolute',
            fontWeight: '600',
            color: 'white',
            fontSize: '20px'
          }}
        >
          {text}
        </span>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
