import React from 'react';
import { CNavItem } from '@coreui/react';
import DashboardIcon from './assets/svg/dashboard.svg';
import Avatar from './assets/svg/userBlack.svg';
const _nav = [
  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: '30px',
        }}
      >
        Dashboard
      </div>
    ),
    to: '/dashboard',
    icon: (
      <img
        alt="dashboard"
        style={{
          width: '30px',
        }}
        src={DashboardIcon}
      />
    ),
  },
  {
    component: CNavItem,
    name: (
      <div
        style={{
          marginLeft: '30px',
        }}
      >
        All Users
      </div>
    ),
    to: '/users',
    icon: (
      <img
        alt="user"
        style={{
          width: '30px',
        }}
        src={Avatar}
      />
    ),
  },
];

export default _nav;
