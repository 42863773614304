import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import LoginPage from '../pages/login';
import DashboardPage from '../pages/dashboard/DashboardLayout';
import PrivateRoute from './privateRoute';
import UserPage from '../pages/users/UserLayout';
import UserDetail from '../pages/users/UserDetail';
import PublicRoute from './PublicRoute';
import Sessions from '../pages/sessions/Sessions';
// import ComingSoon from '../pages/comingSoon';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <PublicRoute><LoginPage /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.USERS,
    element: <PrivateRoute><UserPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.USERS_DETAIL,
    element: <PrivateRoute><UserDetail /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SESSIONS,
    element: <PrivateRoute><Sessions /></PrivateRoute>,
  },
]);

export default routes;
